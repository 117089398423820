import { Layout } from '../components/layout'
import { Section } from '../components/section'
import { Container } from '../components/container'
import { InViewFadeUp } from '../components/in-view-fade'
import { css } from '@emotion/react'
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { useTheme } from '@conte-ltd/components-theme'
import { Theme } from '../styles/theme'
import { useMediaQuery } from '@conte-ltd/components-utils'
import { graphql, PageProps } from 'gatsby'
import { WorksQuery } from '../../graphql-types'
import { WorksGallery } from '../components/works-gallery'
import { Collapse } from '@conte-ltd/components-collapse'
import { Icon } from '../components/icon'

const categories = [
  'ALL',
  'BRANDING',
  'GRAPHIC',
  'LOGO',
  'WEB',
  'EC',
  'PHOTO',
  'PROMOTION',
  'VIDEO',
] as const

type Categories = typeof categories[number]
const SelectCategoryContext = createContext<
  [Categories, Dispatch<SetStateAction<Categories>>]
>(['ALL', () => {}])

function CategorySelect() {
  const {
    styles: {
      color,
      fontFamily,
      fontSize,
      fontWeight,
      letterSpacing,
      lineHeight,
      display,
      padding,
      justifyContent,
      alignItems,
      bgColor,
      textAlign,
    },
    breakpoints: bp,
  } = useTheme<Theme>()
  const isMobile = useMediaQuery(bp.md)

  const [selectedCategory, setSelectedCategory] = useContext(
    SelectCategoryContext
  )
  const [isOpen, setIsOpen] = useState(false)

  if (isMobile) {
    return (
      <div css={[bgColor.lightgray, textAlign.center, padding.y['5xs']]}>
        <div
          css={[
            display.flex,
            justifyContent.center,
            alignItems.center,
            padding.y['3xs'],
          ]}
          onClick={() => {
            setIsOpen(prev => !prev)
          }}
        >
          <p
            css={[
              fontFamily.default,
              fontSize.xs,
              fontWeight.semibold,
              letterSpacing.wide,
            ]}
          >
            <InViewFadeUp>
              CATEGORY
              <Icon
                type={'angle-down'}
                css={[
                  css`
                    margin-left: 0.4em;
                    transform: scale(0.5);
                    transition: transform 0.25s ease-out;
                  `,
                  isOpen &&
                    css`
                      transform: scale(0.5) rotate(180deg);
                    `,
                ]}
              />
            </InViewFadeUp>
          </p>
        </div>

        <Collapse
          isExpanded={isOpen}
          css={css`
            transition: all 0.5s;
          `}
        >
          <ul
            css={[
              fontFamily.default,
              fontSize.xs,
              fontWeight.semibold,
              letterSpacing.wide,
            ]}
          >
            {categories.map(category => (
              <li
                key={category}
                css={[
                  padding.y['3xs'],
                  selectedCategory !== category && [
                    css`
                      cursor: pointer;
                    `,
                    color.gray,
                  ],
                ]}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </li>
            ))}
          </ul>
        </Collapse>
      </div>
    )
  } else {
    return (
      <ul
        css={[
          fontFamily.default,
          fontWeight.semibold,
          letterSpacing.wide,
          lineHeight.none,
          css`
            max-width: 56rem;
            border-top: solid 1px;
            padding-top: 4rem;
            padding-bottom: 10rem;
            display: flex;
            flex-wrap: wrap;
            gap: 4rem;
          `,
        ]}
      >
        {categories.map(category => (
          <li
            key={category}
            css={
              selectedCategory !== category && [
                color.gray,
                css`
                  cursor: pointer;
                  color: #969696;
                `,
              ]
            }
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </li>
        ))}
      </ul>
    )
  }
}

const WorksPage = ({ data }: PageProps<WorksQuery>) => {
  const {
    styles: { fontSize, display, margin, padding },
    breakpoints: bp,
  } = useTheme<Theme>()
  const isMobile = useMediaQuery(bp.md)

  const state = useState<Categories>('ALL')
  const [selectedCategory] = state

  return (
    <SelectCategoryContext.Provider value={state}>
      <Layout>
        <Section css={isMobile ? padding.y['4xl'] : padding.y['5xl']}>
          <Container>
            <Container>
              <h1
                css={[
                  isMobile ? fontSize.xl : fontSize['3xl'],
                  margin.bottom.lg,
                ]}
                className={'light-mode'}
              >
                <InViewFadeUp css={margin.right.negative[7]}>
                  WORKS
                  <br />
                  <small
                    css={[
                      display.inlineBlock,
                      isMobile ? margin.top['2xs'] : margin.top.sm,
                      isMobile
                        ? fontSize.sm
                        : css`
                            font-size: 1.3rem;
                          `,
                    ]}
                  >
                    制作実績
                  </small>
                </InViewFadeUp>
              </h1>
              <CategorySelect />
              <WorksGallery
                nodes={data.allMdx.nodes.filter(
                  ({ frontmatter }) =>
                    selectedCategory === 'ALL' ||
                    frontmatter?.categories?.includes(selectedCategory)
                )}
              />
            </Container>
          </Container>
        </Section>
      </Layout>
    </SelectCategoryContext.Provider>
  )
}

export const query = graphql`
  query Works {
    allMdx(
      filter: { slug: { glob: "works/*" } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      nodes {
        id
        slug
        frontmatter {
          title
          subtitle
          categories
          thumbnail {
            childImageSharp {
              gatsbyImageData
            }
          }
          url
        }
      }
    }
  }
`

export default WorksPage
